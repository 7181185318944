<template>
  <div class="home">
    <img src="../../../public/images/queshengye.png"  width="100%" height="100%">
    <foot></foot>
    <navigation></navigation>
  </div>

</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "comingSoon2",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>

</style>